import React, { useState } from "react";

export const StreamingSelectContext = React.createContext(null);

export const StreamingSelectProvider = ({ children }) => {
  const [streamingSelectVisible, setStreamingSelectVisible] = useState<boolean>(false);
  const [streamingSelectProviders, setStreamingSelectProviders] = useState([]);

  const selectStreamingSelectProviders = (
    visible = true,
    providers = [],
  ) => {
    setStreamingSelectProviders(providers);
    setStreamingSelectVisible(visible);
  };

  return (
    <StreamingSelectContext.Provider
      value={{
        selectStreamingSelectProviders,
        streamingSelectVisible,
        setStreamingSelectVisible,
        streamingSelectProviders,
        setStreamingSelectProviders,
      }}
    >
      {children}
    </StreamingSelectContext.Provider>
  );
};
