import { Content } from "models/content";
import { ContentSortOption } from "models/contentSortOption";
import {
  OrderByOptions,
  ReduxFirestoreQuerySetting,
  WhereOptions,
  withFirestore,
} from "react-redux-firebase";
import firebase from "firebase";
import { compose } from "redux";
import { URL_BASE } from "setup/environment";
import { connect } from "react-redux";
import { withHandlers } from "recompose";
import { Series } from "models/series";
import { User } from "models/user";
import { addNewContent } from "services/backend-api";

export default class ContentService {  
  static getStreamingKey = (service) => {
    return `${(service || "").toLowerCase()}id`;
  }
  
  static createContent = async(args: {
    title: string,
    service?: "Netflix" | "Hulu" | "NYU" | "Youtube",
    streamingId?: string,
    runtime: number,
    user: User,
    type: "movie" | "episode" | "video",
    stills?: { height: number, width: number, url: string, aspect_ratio: number }[],
    plot?: string,
    imdbid?: string,
    tmdbid?: string,
  }) => {
    let newContent = {
      hidden: false,
      activityCount: 0,
      publishedCount: 0,
      allusionCount: 0,
      commentCount: 0,
      timeCreated: new Date(),
      timeUpdated: new Date(),
      title: args.title,
      tempTitle: args.title,
      addedByUser: args.user?.email || null,
      runtime: `${args.runtime} min`,
      type: args.type,
      stills: args.stills || null,
      plot: args.plot || null,
      imdbid: args.imdbid || null,
      tmdbid: args.tmdbid || null,
    };

    const streamingKey = ContentService.getStreamingKey(args.service);
    if (args.service && args.streamingId) {
      newContent[streamingKey] = args.streamingId;
    }
    
    return await addNewContent(newContent);
  }

  static deleteContent = async (content) => {
    return await ContentService.updateContent(content, { deleted: true });

    /* const contentRef = db.collection("Content").doc(content.id); */
    /* await contentRef.delete(); */
  }

  static updateContent = async (content, data) => {
    const db = firebase.firestore();

    if (content?.id) {
      const contentRef = db.collection("Content").doc(content?.id);
      await contentRef.update(data);
      return { ...content, ...data };
    } else {
      console.log("UPDATE CONTENT FAILED");
      return content;
    }
  }
  
  static getTitleWithSeasonEpisode = (
    content: Content,
    includeEpisodeName?: boolean
  ) => {
    if (content) {
      switch (content.type) {
        case "episode":
          let title = `${content.seriesName} S${content.season}E${
            content.episode
          }${includeEpisodeName ? `: ${content.title || ""}` : ``}`;
          return title.replace("&#39;", "'");
        default:
          return ContentService.getTitle(content);
      }
    }
    return "";
  };
  static getTitle = (
    content: Content | Series,
    withSeries?: boolean,
    withYear?: boolean
  ) => {
    let title = "";
    if (content) {
      switch (content.type) {
        case "episode":
          const episode = content as Content;
          if (withSeries) {
            title = `${episode.seriesName}: ${episode.title}${
              withYear && episode.year ? ` (${episode.year})` : ``
            }`;
          } else {
            title = episode.title || "";
          }
          break;
        case "movie":
          const movie = content as Content;
          title = `${movie.title || ""}${
            withYear && movie.year ? ` (${movie.year})` : ``
          }`;
          break;
        case "series":
          const series = content as Series;
          title = `${series.title || ""}${
            withYear && series.year ? ` (${series.year})` : ``
          }`;
          break;
        default:
          title = (content as Content)?.title || "";
          break;
      }
    }
    return title.replace("&#39;", "'");
  };

  static getImage = (content: any, kind: "portrait" | "landscape") => {
    let poster = content ? content.poster : "";

    // backdrop url > uploaded poster > poster url

    if (
      content &&
      content.type === "episode" &&
      kind === "portrait" &&
      content.seriesPoster
    ) {
      return content.seriesPoster;
    }

    if (kind === "landscape" && content) {
      if (content.selectedBackdropUrl) {
        poster = content.selectedBackdropUrl;
      } else if ((content.backdrops || []).length > 0) {
        poster = (content.backdrops || [])[0].url;
      } else if (content.manualPoster && content.manualPoster.src) {
        return content.manualPoster.src;
      } else if ((content.stills || []).length > 0) {
        poster = (content.stills || [])[0].url;
      } else if (content.type === "episode") {
        poster = content.poster;
      }
    } else if (content && content.manualPoster && content.manualPoster.src) {
      return content.manualPoster.src;
    }

    if (poster && poster.includes("theposterdb")) {
      if ((content.posters || []).length > 0) {
        return content.posters[0].url;
      }
    }

    if (poster === "N/A") {
      poster = null;
    }

    return poster || null;
  };

  static getSeriesPoster = async (content: Content) => {
    const series = await ContentService.getSeries(content);
    return series?.manualPoster?.src || series?.poster || "";
  };

  static getDuration = (movie: Content): number => {
    if (movie) {
      let runtime = movie.runtime;
      if (runtime) {
        let time = parseInt(runtime.split(" min")[0]);
        if (!isNaN(time)) {
          return time * 60;
        }
      }
    }

    return 0;
  };

  static getIMDbRating = (content: Content): string => {
    if (content) {
      const filtered = (content.ratings || []).filter(
        (item) => item.Source === "Internet Movie Database"
      );

      if (filtered && filtered[0]) {
        return filtered[0].Value.split("/")[0];
      }
    }

    return null;
  };

  static getTomatometerRating = (content: Content): string => {
    if (content) {
      const filtered = (content.ratings || []).filter(
        (item) => item.Source === "Rotten Tomatoes"
      );

      if (filtered && filtered[0]) {
        return filtered[0].Value;
      }
    }

    return null;
  };

  static getMetacriticRating = (content: Content): string => {
    if (content) {
      const filtered = (content.ratings || []).filter(
        (item) => item.Source === "Metacritic"
      );

      if (filtered && filtered[0]) {
        return filtered[0].Value.split("/")[0];
      }
    }

    return null;
  };

  // Generic fn to query firestore
  // Supports filters and sort
  static queryableContent = compose<any>(
    withFirestore,
    withHandlers({
      getContent:
        (props) =>
        (
          filters: WhereOptions[],
          sortOptions: ContentSortOption,
          limit = 10
        ) => {
          const orderBy: OrderByOptions = [sortOptions.field, sortOptions.sort];
          const query: ReduxFirestoreQuerySetting = {
            collection: "Content",
            where: filters, // eg: [["type", "==", 'movie']]
            orderBy: orderBy,
            // limit: limit,
            storeAs: "content",
          };
          props.firestore.get(query);
        },
      getSeries:
        (props) =>
        (
          filters: WhereOptions[],
          sortOptions: ContentSortOption | null,
          limit = 10
        ) => {
          const orderBy: OrderByOptions = sortOptions && [
            sortOptions.field,
            sortOptions.sort,
          ];
          const query: ReduxFirestoreQuerySetting = {
            collection: "Series",
            where: filters, // eg: [["type", "==", 'movie']]
            orderBy: orderBy,
            // limit: limit,
            storeAs: "series",
          };
          props.firestore.get(query);
        },
    }),
    connect((state: any) => ({
      content: state.firestore.data.content,
      series: state.firestore.data.series,
    }))
  );

  static runtimeTextToTimecodeSeconds = (runtime: string) => {
    let timeMinutes = 0;
    const parsedTimeSeconds = parseInt(runtime.split(" min")[0]);
    if (!isNaN(parsedTimeSeconds)) {
      timeMinutes += parsedTimeSeconds;
    }

    /* if (runtime) {
     *   let mat = runtime.match(/\d+\s?(min|sec)?/);
     *   let timeInt = parseInt(mat[1]);
     *   if (!isNaN(timeInt)) {
     *     if (mat[2] !== "sec") {
     *       timeInt *= 60;
     *     }
     *     return timeInt;
     *   }
     * } */

    return timeMinutes * 60;
  };

  static runtimeTextToTimecode = (runtime: number) => {
    let timeSeconds = 0;
    const parsedTimeSeconds = parseInt(runtime.toString().split(" min")[0]);
    if (!isNaN(parsedTimeSeconds)) {
      timeSeconds += parsedTimeSeconds;
    }

    const hours = Math.floor(timeSeconds / 3600);
    const mins = Math.floor((timeSeconds - hours * 3600) / 60);
    // const secs = Math.floor(timeSeconds - hours * 3600 - mins * 60)
    return `${hours.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}`;
  };

  static getAllusionCount = async (contentId: string): Promise<number> => {
    if (!contentId) {
      return 0;
    }

    return await firebase
      .firestore()
      .collection("Allusions")
      .where("contentId", "==", contentId)
      .get()
      .then(
        (querySnapshot) =>
          querySnapshot.docs.filter((doc) => !doc.data().deleted).length
      );
  };

  static getVoteCount = async (contentId: string): Promise<number> => {
    if (!contentId) {
      return 0;
    }

    return await firebase
      .firestore()
      .collection("Allusions")
      .where("contentId", "==", contentId)
      .where("upvotes", ">", 0)
      .get()
      .then((querySnapshot) =>
        querySnapshot.docs.reduce((total, doc) => {
          const data = doc.data();
          const votes = data.deleted ? 0 : data.upvotes || 0;
          return total + votes;
        }, 0)
      );
  };

  static getSeries = async (content: Content): Promise<Series> => {
    if (!content?.seriesid) {
      return undefined;
    }

    return await firebase
      .firestore()
      .collection("Series")
      .doc(content.seriesid)
      .get()
      .then((doc) => doc.data() as Series);
  };

  // tara later this is not the best way
  static getNextEpisode = async (content: Content): Promise<Content> => {
    let episodeData = await firebase
      .firestore()
      .collection("Content")
      .where("seriesid", "==", content.seriesid)
      .where("season", "in", [
        content.season,
        `${parseInt(content.season) + 1}`,
      ])
      .get();
    let episodes = [];
    episodeData.forEach((ep) => episodes.push({ ...ep.data(), id: ep.id }));
    episodes = episodes.filter(
      (item) =>
        (item.season === content.season &&
          parseInt(item.episode) > parseInt(content.episode)) ||
        parseInt(item.season) > parseInt(content.season)
    );
    episodes.sort((a, b) => {
      if (parseInt(a.season) < parseInt(b.season)) {
        return -1;
      }
      if (parseInt(a.season) > parseInt(b.season)) {
        return 1;
      }
      if (parseInt(a.episode) > parseInt(b.episode)) {
        return 1;
      }
      if (parseInt(a.episode) < parseInt(b.episode)) {
        return -1;
      }
      return 0;
    });

    return episodes.length ? episodes[0] : null;
  };

  /* Check if content is supported by all requested services */
  static isStreamingOn(content: Content, services: string[]) {
    return ContentService._getStreamingServices(content).every(
      (service) => services.indexOf(service) + 1
    );
  }

  static getStreamingId(content: Content, service: string) {
    return service === "alluder" ? ( content.id ) : (
      content[`${service.toLowerCase()}id`] || ""
    );
  }

  /*****************************************************************************
   * Semi-private (not for general use!!!)
   *****************************************************************************/

  static _getStreamingServices(content: Content) {
    return Object.keys(content || {}).reduce((services, key) => {
      let mat = key.match(/(netflix|hulu|nyu|youtube)id/);
      if (mat && content[mat[0]]) {
        if (mat[1] === "nyu") {
          services.push("NYU"); // !blw: maybe just always use lower case...
        } else {
          let service = mat[1].charAt(0).toUpperCase() + mat[1].slice(1);
          services.push(service);
        }
      }
      return services;
    }, []);
  }

  static fetchContentByImdbId = async (imdbid) => {
    if (!imdbid) return null;
    
    return await firebase
      .firestore()
      .collection("Content")
      .where("imdbid", "==", imdbid)
      .get()
      .then(
        (snap) => {
          const filtered = (
            snap.docs.map((doc) => ({ ...doc.data(), id: doc.id })) || []
          )
          //@ts-ignore
            .filter(item => !item.deleted);
          return filtered[0] || null;
        }
      );
  }

  static fetchContentByTmdbId = async (tmdbid) => {
    if (!tmdbid) return null;
    
    return await firebase
      .firestore()
      .collection("Content")
      .where("tmdbid", "==", tmdbid)
      .get()
      .then(
        (snap) => {
          const filtered = (
            snap.docs.map((doc) => ({ ...doc.data(), id: doc.id })) || []
          )
          //@ts-ignore
            .filter(item => !item.deleted);
          return filtered[0] || null;
        }
      );
  }

  static fetchSeriesByImdbId = async (imdbid) => {
    if (!imdbid) return null;
    
    return await firebase
      .firestore()
      .collection("Series")
      .doc(imdbid)
      .get()
      .then(
        (doc) => doc.exists ? { ...doc.data(), imdbid } : null
      );
  }

  static fetchContentById = async (id) => {
    if (!id) return null;
    
    return await firebase
      .firestore()
      .collection("Content")
      .doc(id)
      .get()
      .then(
        (doc) => doc.exists ? { ...doc.data(), id } : null
      );
  }
}
