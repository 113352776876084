import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useLogin } from "hooks/useLogin";
import { useMe } from "hooks/useMe";
import { DefaultColors } from "models/colors";
import Footer from "navigation/footer";
import React, { useEffect, useState } from "react";
import env from "react-dotenv";
import { useMediaQuery } from "react-responsive";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import LoadingAnimation from "components/animations/loading";
import { AnalyticsService } from "services/analytics-service";
import DataVis from "screens/data-vis/data-vis";

const AboutUsPage = React.lazy(() => import("screens/alluder-101/about-us"));
const AllusionsPage = React.lazy(() => import("screens/alluder-101/allusions"));
const ComingSoonPage = React.lazy(
  () => import("screens/alluder-101/coming-soon")
);
const HelpPage = React.lazy(() => import("screens/alluder-101/help"));
const CommunitiesHome = React.lazy(
  () => import("screens/community/all-communities")
);
const CommunityDetail = React.lazy(
  () => import("screens/community/community-detail")
);
const ContactUs = React.lazy(() => import("screens/contact-us/contact-us"));
const AllusionDetail = React.lazy(
  () => import("screens/content-detail/allusion-detail")
);
const ContentDetail = React.lazy(
  () => import("screens/content-detail/content-detail")
);
const CareerPage = React.lazy(
  () => import("screens/landing-page/careers-page")
);
const AllNotificationsScreen = React.lazy(
  () => import("screens/notifications/all-notifications")
);
const About = React.lazy(() => import("screens/onboarding/about"));
const HowItWorks = React.lazy(() => import("screens/onboarding/how-it-works"));
const Onboarding = React.lazy(() => import("screens/onboarding/onboarding"));
const PostsHome = React.lazy(() => import("screens/posts/posts"));
const ProfileActivity = React.lazy(
  () => import("screens/profile/profile-activity")
);
const ProfileBadges = React.lazy(
  () => import("screens/profile/profile-badges")
);
const ProfileBookmarks = React.lazy(
  () => import("screens/profile/profile-bookmarks")
);
const ProfileContinueAll = React.lazy(
  () => import("screens/profile/profile-continue-all")
);
const ProfileNetwork = React.lazy(
  () => import("screens/profile/profile-network")
);
const ProfilePosts = React.lazy(() => import("screens/profile/profile-posts"));
const ProfileStills = React.lazy(
  () => import("screens/profile/profile-stills")
);
const ProfileSaved = React.lazy(() => import("screens/profile/profile-saved"));
const ProfileUploads = React.lazy(
  () => import("screens/profile/profile-uploads")
);
const ProfileSettings = React.lazy(
  () => import("screens/profile/profile-settings")
);
const ProfileWatchAll = React.lazy(
  () => import("screens/profile/profile-watch-all")
);
const SeriesItem = React.lazy(() => import("screens/series/series-item"));
const ContentPolicy = React.lazy(() => import("screens/static/content-policy"));
const CookieNotice = React.lazy(() => import("screens/static/cookie-notice"));
const PrivacyPolicy = React.lazy(() => import("screens/static/privacy-policy"));
const TOS = React.lazy(() => import("screens/static/tos"));
const Home = React.lazy(() => import("../../screens/home/home"));
const LandingPage = React.lazy(
  () => import("../../screens/landing-page/landing-page")
);
const MoviesHome = React.lazy(() => import("../../screens/movies/movies-home"));
const SeriesHome = React.lazy(() => import("../../screens/series/series-home"));
const AlludeNavBar = React.lazy(() => import("../header/header"));
const MobileNavBar = React.lazy(() => import("../header/mobile-header"));

const AdminContent = React.lazy(() => import("screens/admin/content"));
const AdminSubtitles = React.lazy(() => import("screens/admin/subtitles"));
const AdminImdb = React.lazy(() => import("screens/admin/imdb"));
const AdminReddit = React.lazy(() => import("screens/admin/reddit"));

const CinemetricsLanding = React.lazy(() => import("screens/cinemetrics/landing"));
const FACT = React.lazy(() => import("screens/cinemetrics/fact"));
const CinemetricsClassic = React.lazy(() => import("screens/cinemetrics/classic"));
const CinemetricsUpload = React.lazy(() => import("screens/cinemetrics/upload"));
const Notes = React.lazy(() => import("screens/notes/notes"));
const Local = React.lazy(() => import("screens/local/local"));

function RouteItem({
  path,
  routeItem,
  requireLoggedIn,
  requireAdmin,
  loginVisibleOnLoad,
  hideFooter,
  hideHeader,
  loading,
  loginVisible,
}: {
  path: any;
  routeItem: any;
  requireLoggedIn?: boolean;
  requireAdmin?: boolean;
  loginVisibleOnLoad?: boolean;
  hideFooter?: boolean;
  hideHeader?: boolean;
  loading: boolean;
  loginVisible: boolean;
}) {
  const me = useMe();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const isLoaded = me && me.isLoaded;

  const isLoggedIn = React.useMemo(() => {
    return isLoaded && !me.isEmpty && me.username;
  }, [me]);

  const isAdmin = React.useMemo(() => {
    return isLoaded && !me.isEmpty && me.serviceWorker;
  }, [me])

  const useRouteFallback = React.useMemo(() => {
    console.log(isLoggedIn, isAdmin)
    
    return (
      isLoaded &&
      ((requireLoggedIn && !isLoggedIn) ||
       (requireAdmin && !isAdmin)) &&
      !(path === "/landing" || path === "/")
    );
  }, [isLoaded, isLoggedIn, requireLoggedIn]);

  const classes = useStyles();

  const NavBar = isMobile ? MobileNavBar : AlludeNavBar;

  const showLoadingAnimation = React.useMemo(() => {
    return false;
    if (path == "/landing" || path == "/") {
      return false;
    }
    return loading;
  }, [path, loading]);

  return (
    <Route path={path}>
      <section className={classes.container}>
        {hideHeader ? (
          <div />
        ) : showLoadingAnimation ? (
          <header />
        ) : (
          <NavBar
            loginVisibleOnLoad={
              isLoaded && !isLoggedIn ? loginVisibleOnLoad || false : false
            }
          />
        )}
        
        <main>
          {isMobile && loginVisible && !(path == "/landing" || path == "/") ? (
            <div />
          ) : useRouteFallback ? (
            <LandingPage />
          ) : (
            routeItem
          )}
        </main>

        {showLoadingAnimation || (isMobile && loginVisible) || hideFooter ? (
          <div />
        ) : (
          <Footer />
        )}
      </section>
    </Route>
  );
}

export default function AlludeRouter() {
  const me = useMe();
  const [lastScreen, setLastScreen] = useState("");
  const [loading, setLoading] = useState(true);
  const { loginVisible } = useLogin();

  const isLandingPage = React.useMemo(() => {
    return (
      window.location.pathname == "/landing" || window.location.pathname == "/"
    );
  }, []);

  useEffect(() => {
    if (isLandingPage) {
      setLoading(false);
    } else {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    if (me && me.id) {
      AnalyticsService.setUserId(me.id);
    }
  }, [me]);

  const logCurrentPage = () => {
    let newScreen = `${window.location.pathname}`;
    if (lastScreen !== newScreen) {
      AnalyticsService.logScreen(newScreen);
      setLastScreen(newScreen);
    }
  };

  const AnalyticsComponent = () => {
    const history = useHistory();

    useEffect(() => {
      logCurrentPage(); // log the first page visit
      history.listen(() => {
        logCurrentPage();
      });

      window.scrollTo(0, 0);
    }, [history]);
    return <div></div>;
  };

  return (
    <div>
      <Router>
        <AnalyticsComponent />
        <Switch>
          <Route path="/onboarding">
            <Onboarding />
          </Route>

          <Route path="/howitworks">
            <HowItWorks />
          </Route>

          <Route path="/local">
            <Local />
          </Route>

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/admin/content"
            routeItem={<AdminContent />}
            requireLoggedIn
            requireAdmin
            hideHeader
            hideFooter
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/subtitles"
            routeItem={<AdminSubtitles />}
          //requireLoggedIn
          //requireAdmin
          //hideHeader
            hideFooter
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/notes/:contentId?"
            routeItem={<Notes />}
          //requireLoggedIn
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/cinemetrics/classic"
            routeItem={<CinemetricsClassic />}
          />

          <Route path="/cinemetrics/fact">
            <FACT />
          </Route>

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/cinemetrics/upload"
            routeItem={<CinemetricsUpload />}
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/cinemetrics"
            routeItem={<CinemetricsLanding />}
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/home"
            routeItem={<Home />}
            requireLoggedIn={true}
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/help"
            routeItem={<HelpPage />}
          />
          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/about-us"
            routeItem={<About />}
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/alluder-101/allusions"
            routeItem={<AllusionsPage />}
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/alluder-101/coming-soon"
            routeItem={<ComingSoonPage />}
          />
          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/alluder-101/about-us"
            routeItem={<AboutUsPage />}
          />
          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/help"
            routeItem={<HelpPage />}
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/contact"
            routeItem={<ContactUs />}
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/careers"
            routeItem={<CareerPage />}
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/privacy"
            routeItem={<PrivacyPolicy />}
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/tos"
            routeItem={<TOS />}
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/content-policy"
            routeItem={<ContentPolicy />}
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/cookie-notice"
            routeItem={<CookieNotice />}
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/profile/:userId/activity/continue-all"
            routeItem={<ProfileContinueAll />}
            requireLoggedIn={true}
          />
          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/profile/:userId/activity/watch-all"
            routeItem={<ProfileWatchAll />}
            requireLoggedIn={true}
          />
          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/profile/:userId/activity"
            routeItem={<ProfileActivity />}
            requireLoggedIn={true}
          />
          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/profile/:userId/posts"
            routeItem={<ProfilePosts />}
            loginVisibleOnLoad
            hideFooter
          />
          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/profile/:userId/stills"
            routeItem={<ProfileStills />}
            loginVisibleOnLoad
            hideFooter
          />
          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/profile/:userId/saved"
            routeItem={<ProfileSaved />}
            requireLoggedIn={true}
            loginVisibleOnLoad
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/profile/:userId/bookmarks"
            routeItem={<ProfileBookmarks />}
            loginVisibleOnLoad
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/profile/:userId/badges"
            routeItem={<ProfileBadges />}
            loginVisibleOnLoad
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/profile/:userId/network/:tabId"
            routeItem={<ProfileNetwork />}
            loginVisibleOnLoad
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/profile/:userId/uploads"
            routeItem={<ProfileUploads />}
            requireLoggedIn={true}
            loginVisibleOnLoad
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/profile/:userId/settings"
            routeItem={<ProfileSettings />}
            requireLoggedIn={true}
            loginVisibleOnLoad
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/movies"
            routeItem={<MoviesHome />}
          />
          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/posts"
            routeItem={<PostsHome />}
          />
          <RouteItem
            loading={loading}
            loginVisible={false}
            path="/tags-explorer"
            routeItem={<DataVis />}
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/communities/:id"
            routeItem={<CommunityDetail />}
          />
          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/communities"
            routeItem={<CommunitiesHome />}
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/series/:id"
            routeItem={<SeriesItem />}
          />
          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/series"
            routeItem={<SeriesHome />}
          />
          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/notifications"
            routeItem={<AllNotificationsScreen />}
            requireLoggedIn={true}
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/allusion/:allusionId"
            routeItem={<AllusionDetail />}
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path={["/content/:id/allusions/:tickIdx", "/content/:id/"]}
            routeItem={<ContentDetail />}
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/login"
            routeItem={<LandingPage />}
            requireLoggedIn={true}
            loginVisibleOnLoad
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/home"
            routeItem={<Home />}
            requireLoggedIn={true}
          />

          {/* <RouteItem
              loading={loading}
              loginVisible={loginVisible}
              path="/admin/content"
              routeItem={<AdminContent />}
              requireLoggedIn={true}
              requireAdmin={true}
              /> */}

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/admin/imdb"
            routeItem={<AdminImdb />}
            requireLoggedIn
            requireAdmin
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/admin/reddit"
            routeItem={<AdminReddit />}
            requireLoggedIn
            requireAdmin
          />

          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/landing"
            routeItem={<LandingPage />}
          />
          <RouteItem
            loading={loading}
            loginVisible={loginVisible}
            path="/"
            routeItem={<LandingPage />}
          />
        </Switch>
      </Router>
      {loading && <LoadingAnimation />}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      minHeight: "100vh",
      position: "relative",

      "& > header": {
        height: 56,
      },
      "& > main": {
        paddingBottom: 16,
      },
      "& > footer": {
        height: "100%",

        "&.mobile": {
          height: "unset",
        },
      },
    },
  })
);
