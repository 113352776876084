import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import "./styles/app.scss";
import Favicon from "react-favicon";
import App from "./App";
import * as serviceWorker from "./setup/serviceWorker";
import LoadingAnimation from "components/animations/loading";

ReactDOM.render(
  <div>
    <React.Suspense fallback={<LoadingAnimation />}>
      <App />
    </React.Suspense>
    <Favicon url={require("./assets/icons/allude-icon.svg")} />
  </div>,
  document.getElementById("root")
);

serviceWorker.unregister();
