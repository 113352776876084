import { Typography } from "@material-ui/core";
import { DefaultColors } from "models/colors";
import React from "react";

export default function GradientText(props: { text: string; style?: any; onClick?;}) {
  return (
    <Typography
      onClick={props.onClick}
      style={{
        fontSize: 16,
        fontWeight: 500,
        marginLeft: 8,
        background: DefaultColors.gradientColorReverse,
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        ...props.style,
      }}
    >
      {props.text}
    </Typography>
  );
}
