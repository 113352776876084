import { applyMiddleware, compose, createStore } from "redux";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import 'firebase/storage';
import { persistStore, persistReducer } from "redux-persist";
import localStorage from "redux-persist/lib/storage";
import makeRootReducer from "./reducers";

import { routerMiddleware } from "connected-react-router";
import { reduxFirestore, getFirestore } from "redux-firestore";
import thunk from "redux-thunk";
import "firebase/auth";
import "firebase/firestore";

/* const config = process.env.NODE_ENV === "production" ?
 *                require("../setup/FIREBASE_CONFIG").firebaseConfig
 *              : require("../setup/FIREBASE_CONFIG").devFirebaseConfig; */
const config = require("../setup/FIREBASE_CONFIG").firebaseConfig;

firebase.initializeApp(config);
firebase.firestore();
export const FBStorage = firebase.storage();

const persistConfig = {
  key: "root",
  storage: localStorage,
};

export default (initialState = {}, history) => {
  let reducer = makeRootReducer(history);

  const composeEnhancers =
    (process.env.NODE_ENV === "development" &&
      typeof window !== "undefined" &&
      //@ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      //@ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose;

  const persistedReducer = persistReducer(persistConfig, reducer);

  const store = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        thunk.withExtraArgument({ getFirestore })
      ),
      //@ts-ignore
      reduxFirestore(firebase)
    )
  );

  const persistor = persistStore(store);

  return { store, persistor };
};
