import { Box, Button, Typography } from "@material-ui/core";
import { DefaultColors } from "models/colors";
import React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export function CTAButton(props: {
  onClick?: any;
  onSubmit?: any;
  icon?: any;
  inlineIcon?: any;
  style?: any;
  text: string;
  paddingHorizontal?: number;
  pr?: number;
  disable?: boolean;
  type?: "button"|"reset"|"submit";
}) {
  const classes = useStyles();
  const pr = props.pr === undefined ? 1 : props.pr;

  return (
    <Box pr={pr}>
      <Button
        type={props.type}
        onSubmit={props.disable ? undefined : props.onSubmit}
        onClick={props.disable ? undefined : props.onClick}
        variant="contained"
        style={{
          background: props.disable ? "rgba(255, 255, 255, 0.10)" : DefaultColors.gradientColor,
          whiteSpace: "nowrap",
          color: props.disable ? "rgba(255,255,255, 0.25) !important" : DefaultColors.dark,
          ...props.style
        }}
        className={
        props.paddingHorizontal
        ? `alludeRoundedBtn horiz80`
        : `alludeRoundedBtn`
        }
        endIcon={props.icon}
      >
        {props.paddingHorizontal ? (
          <span
            style={{
              marginLeft: props.paddingHorizontal,
              marginRight: props.paddingHorizontal
            }}
            className={classes.texticon}
          >
            {props.text} {props.inlineIcon ? <span><img src={props.inlineIcon} alt={props.text} /></span> : ''}
          </span>
        ) : (
          <span className={classes.texticon}>
            {props.text} {props.inlineIcon ? <span><img src={props.inlineIcon} alt={props.text} /></span> : ''}
          </span>
        )}
      </Button>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    texticon: {
      '& span': {
        position: 'relative'
      },
      '& img': {
        position: 'absolute',
        width: '14px',
        height: '14px',
        top: '1px',
        left: '5px'
      }
    }
  })
)
