/*****************************************************************************
 * Imports
 *****************************************************************************/
import React from "react";
import { Typography } from "@material-ui/core/";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { DefaultColors } from "models/colors";

/*****************************************************************************
 * Component
 *****************************************************************************/
interface TypeProps {
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "b1" | "b2" | "b3" | "b4";
  children: React.ReactNode[] | React.ReactNode;
  style?: React.CSSProperties;
  onClick?: any;
  link?: boolean;
  gradient?: boolean;
  className?: any;
}

function Type({
  variant,
  children,
  style,
  onClick,
  link,
  gradient,
  className,
}: TypeProps) {
  const classes = useStyles();
  const primaryClassName = [classes[variant || "b1"], link && classes.link]
    .filter((item) => !!item)
    .join(" ");

  const gradientStyle = {
    background: DefaultColors.gradientColorReverse,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  };

  return (
    <Typography
      className={`${primaryClassName} ${className}`}
      style={{ ...(gradient ? gradientStyle : {}), ...style }}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
}

/*****************************************************************************
 * Style
 *****************************************************************************/
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    h1: {
      [theme.breakpoints.down(760)]: {
        fontWeight: 900,
        fontSize: "16px",
        lineHeight: "30px",
        textTransform: "uppercase",
      },
      [theme.breakpoints.up(760)]: {
        fontWeight: 800,
        fontSize: "24px",
        lineHeight: "28px",
        textTransform: "uppercase",
      },
    },
    h2: {
      [theme.breakpoints.down(760)]: {
        fontWeight: 900,
        fontSize: "14px",
        lineHeight: "22px",
        textTransform: "uppercase",
      },
      [theme.breakpoints.up(760)]: {
        fontWeight: 800,
        fontSize: "16px",
        lineHeight: "28px",
        textTransform: "uppercase",
      },
    },
    h3: {
      [theme.breakpoints.down(760)]: {
        fontWeight: 900,
        fontSize: "10px",
        lineHeight: "20px",
        textTransform: "uppercase",
        letterSpacing: "0.1em",
      },
      [theme.breakpoints.up(760)]: {
        fontWeight: 900,
        fontSize: "12px",
        lineHeight: "20px",
        textTransform: "uppercase",
      },
    },
    h4: {
      [theme.breakpoints.down(760)]: {
        fontWeight: 700,
        fontSize: "10px",
        lineHeight: "22px",
        textTransform: "uppercase",
      },
      [theme.breakpoints.up(760)]: {
        fontWeight: 800,
        fontSize: "13px",
        lineHeight: "20px",
        textTransform: "uppercase",
      },
    },
    h5: {
      [theme.breakpoints.down(760)]: {
        fontWeight: 700,
        fontSize: "8px",
        lineHeight: "20px",
        textTransform: "uppercase",
      },
      [theme.breakpoints.up(760)]: {
        fontWeight: 800,
        fontSize: "10px",
        lineHeight: "20px",
        textTransform: "uppercase",
      },
    },
    h6: {
      [theme.breakpoints.down(760)]: {
        fontWeight: 700,
        fontSize: "8px",
        lineHeight: "20px",
        textTransform: "uppercase",
      },
      [theme.breakpoints.up(760)]: {
        fontWeight: 800,
        fontSize: "8px",
        lineHeight: "20px",
        textTransform: "uppercase",
      },
    },

    b1: {
      [theme.breakpoints.down(760)]: {
        fontWeight: 500,
        fontSize: "13px",
        lineHeight: "22px",
      },
      [theme.breakpoints.up(760)]: {
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "22px",
      },
    },
    b2: {
      [theme.breakpoints.down(760)]: {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "22px",
      },
      [theme.breakpoints.up(760)]: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
      },
    },
    b3: {
      [theme.breakpoints.down(760)]: {
        fontWeight: 400,
        fontSize: "10px",
        lineHeight: "20px",
      },
      [theme.breakpoints.up(760)]: {
        fontWeight: 500,
        fontSize: "13px",
        lineHeight: "20px",
      },
    },
    b4: {
      [theme.breakpoints.down(760)]: {
        fontWeight: 400,
        fontSize: "10px",
        lineHeight: "20px",
      },
      [theme.breakpoints.up(760)]: {
        fontWeight: 500,
        fontSize: "8px",
        lineHeight: "18px",
      },
    },

    link: {
      fontWeight: 700,
      background: DefaultColors.gradientColorReverse,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
  })
);

/*****************************************************************************
 * Export
 *****************************************************************************/
export default Type;
