/*****************************************************************************
 * Imports
 *****************************************************************************/
import { Box, CircularProgress } from "@material-ui/core";
import { useMobileCheck } from "hooks/useMobileCheck";
import qs from "qs";
import React, { useState } from "react";
import { ForceGraph3D } from "react-force-graph";
import { useFirestore } from "react-redux-firebase";
import { useHistory, useLocation } from "react-router-dom";
import ContentService from "services/content-service";
import { getAllusionValueForTagTitle } from "services/tag-service";
import SpriteText from "three-spritetext";
const ALLUSION_QUERY_URL = `https://kixzgwp2csyt41qap-1.a1.typesense.net:443/collections/Allusions/documents/search?per_page=30&query_by=tags&q=`;

/*****************************************************************************
 * Component
 *****************************************************************************/

function DataVis() {
  const location = useLocation();
  const firestore = useFirestore();
  const isMobile = useMobileCheck();
  const history = useHistory();
  const fgRef = React.useRef();

  const [loading, setLoading] = useState(false);
  const [contentItems, setContentItems] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [posts, setPosts] = useState([]);

  /*************************************
   * Effects
   *************************************/
  React.useEffect(() => {
    getFilterFromParams();
  }, [location]);

  const getFilterFromParams = async () => {
    const data = location.search;
    const params = qs.parse(data, { ignoreQueryPrefix: true });

    if (params && params.tag) {
      let tag = params.tag as string;
      tag = decodeURI(tag);
      tag = getAllusionValueForTagTitle(tag);

      setSelectedTags([tag]);
      setLoading(true);
      setContentItems([]);
      setPosts([]);
      fetchAllusions(tag);
    }
  };

  const getHeaders = () => {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-TYPESENSE-API-KEY", "f1Yl1yR9QPTY3353wT9lzV8ccRYcfKPc");
    return headers;
  };

  const fetchAllusions = (query: string) => {
    let headers = getHeaders();
    fetch(`${ALLUSION_QUERY_URL}${query}`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((json) => {
        let postItems = (json.hits || [])
          //   .filter((item) => item.text_match > 49112068)
          .map((item) => item.document);
        console.log("json", json);
        setPosts(postItems); //.filter((item) => item.tags.includes(query))
        fetchContent(postItems);
        setLoading(false);
      });
  };

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const fetchContent = (postItems: { contentId: string }[]) => {
    let unique = postItems.map((item) => item.contentId).filter(onlyUnique);
    Promise.all(
      [...unique].map((contentId) => {
        return firestore
          .collection("Content")
          .doc(contentId)
          .get()
          .then((snap) => {
            return { ...snap.data(), id: contentId };
          });
      })
    )
      .then((fetchedContent) =>
        //@ts-ignore
        fetchedContent.filter((item) => ContentService.getTitle(item))
      )
      .then((fetchedContent) => {
        setContentItems(fetchedContent);
      });
  };

  const getRandomColor = () => {
    let luma = 0;
    var c = "";
    while (luma < 20) {
      var c = Math.floor(Math.random() * 16777215).toString(16);
      var rgb = parseInt(c, 16); // convert rrggbb to decimal
      var r = (rgb >> 16) & 0xff; // extract red
      var g = (rgb >> 8) & 0xff; // extract green
      var b = (rgb >> 0) & 0xff; // extract blue

      luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    }
    return `#${c}`;
  };

  const getTitleForContent = (contentId: string) => {
    let c = contentItems.find((item) => item.id == contentId);
    if (c) {
      return ContentService.getTitle(c);
    }
    return "";
  };

  const tagCounts = React.useMemo(() => {
    let postsTagsCounts = {};
    posts.forEach((post, index) => {
      (post.tags || []).forEach((pt) => {
        if (!Object.keys(postsTagsCounts).includes(pt)) {
          postsTagsCounts[pt] = 1;
        } else {
          postsTagsCounts[pt] = postsTagsCounts[pt] + 1;
        }
      });
    });
    return postsTagsCounts;
  }, [posts]);

  const data = React.useMemo(() => {
    let postsTags = [];
    let contentIds = [];
    let postLinksCounts = {};
    let postNodes = [];
    let postLinks = [];
    let contentIdColors = {};
    posts.forEach((post, index) => {
      // let color = "#fff";
      // if (Object.keys(contentIdColors).includes(post.contentId)) {
      //   color = contentIdColors[post.contentId];
      // } else {
      //   let randomColor = getRandomColor();
      //   color = randomColor;
      //   contentIdColors[post.contentId] = randomColor;
      // }
      if (!contentIds.includes(post.contentId)) {
        let item = contentItems.find((item) => item.id == post.contentId);
        if (item) {
          postNodes.push({
            id: post.contentId,

            group: index + 2,
            contentId: post.contentId,
            // color: color,
            count: 1, //tagCounts[pt],
            // tag: pt,
            contentTitle: getTitleForContent(post.contentId),
          });
          contentIds.push(post.contentId);
        }
      }
      // (post.tags || []).forEach((pt) => {
      //   if (!selectedTags.includes(pt)) {
      //     if (!postsTags.includes(pt)) {
      // postNodes.push({
      //   id: pt, //`${post.contentId}-${pt}`,
      //   group: index + 2,
      //   contentId: post.contentId,
      //   color: color,
      //   count: tagCounts[pt],
      //   tag: pt,
      //   contentTitle: getTitleForContent(post.contentId),
      // });
      //       postsTags.push(pt);
      //     }
      //   }
      // });
    });

    let allContentIds = posts.map((item) => item.contentId);
    allContentIds.forEach((idOne) => {
      allContentIds.forEach((idTwo) => {
        if (idOne != idTwo) {
          let itemA = idOne < idTwo ? idOne : idTwo;
          let itemB = idOne < idTwo ? idTwo : idOne;
          let key = `${itemA}[SPLIT]${itemB}`;
          if (Object.keys(postLinksCounts).includes(key)) {
            postLinksCounts[key] = postLinksCounts[key] + 1;
          } else {
            postLinksCounts[key] = 1;
          }
        }
      });
    });

    // posts.forEach((post, index) => {
    //   let allPostTags = [...(post.tags || [])];
    //   allPostTags.forEach((tagOne) => {
    //     allPostTags.forEach((tagTwo) => {
    //       if (tagOne != tagTwo) {
    //         let itemA = tagOne < tagTwo ? tagOne : tagTwo;
    //         let itemB = tagOne < tagTwo ? tagTwo : tagOne;
    //         let key = `${itemA}[SPLIT]${itemB}`;
    //         if (Object.keys(postLinksCounts).includes(key)) {
    //           postLinksCounts[key] = postLinksCounts[key] + 1;
    //         } else {
    //           postLinksCounts[key] = 1;
    //         }
    //       }
    //     });
    //   });
    // });

    console.log("postLinksCounts", postLinksCounts);

    Object.keys(postLinksCounts).forEach((key) => {
      let itemA = key.split("[SPLIT]")[0];
      let itemB = key.split("[SPLIT]")[1];
      postLinks.push({
        source: itemA,
        target: itemB,
        value: 25,
        // postLinksCounts[key],
      });
    });

    let items = {
      nodes: [
        ...selectedTags.map((item) => ({
          id: item,
          group: 1,
          color: "#fff",
          tag: item,
        })),
        ...postNodes,
      ],
      links: [...postLinks],
    };
    return items;
  }, [selectedTags, posts, contentItems]);

  /*************************************
   * Render
   *************************************/

  React.useEffect(() => {
    console.log("data", data);
  }, [data]);

  const handleClick = React.useCallback(
    (node) => {
      console.log("node", node);
      history.push(`/tags-explorer?tag=${node.tag}`);
    },
    [fgRef]
  );

  return (
    <React.Fragment>
      {selectedTags.length === 0 || contentItems.length == 0 || loading ? (
        <Box style={{ minHeight: "80vh" }}>
          <LoadingState />
        </Box>
      ) : (
        <div>
          <ForceGraph3D
            ref={fgRef}
            graphData={data}
            // nodeLabel={"contentTitle"}
            linkColor="#fff"
            nodeAutoColorBy="id"
            onNodeClick={handleClick}
            nodeThreeObject={(node) => {
              const sprite = new SpriteText(node.contentTitle);
              sprite.color = node.color; // "#fff";

              sprite.fontWeight = selectedTags.includes(node.id)
                ? "bold"
                : "normal";
              let height = node.count * 1.6;
              sprite.textHeight = selectedTags.includes(node.id)
                ? 50
                : height > 30
                ? 30
                : height < 5
                ? 8
                : height;
              return sprite;
            }}
          />
        </div>
      )}
    </React.Fragment>
  );
}

/*****************************************************************************/

const LoadingState = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        width: "100%",
        minHeight: "192px",
        backgroundColor: "#1F1F1F",
        borderRadius: "10px",
        gap: "56px",
        marginTop: 12,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <CircularProgress style={{ height: 32, width: 32 }} color="inherit" />
    </Box>
  );
};

/*****************************************************************************
 * Export
 *****************************************************************************/
export default DataVis;
