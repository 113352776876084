import { User } from "models/user";
import { useSelector } from "react-redux";
import { checkIsExtension } from "extension/services/environment-service";

export const useMe = (): User => {
  const authData = useSelector<any, any>((state) => state.firebase.auth);
  const profileData = useSelector<any, any>((state) => state.firebase.profile);
  const me = {
    ...profileData,
    id: authData.uid,
    isEmpty: authData.isEmpty || profileData.isEmpty,
    isLoaded: authData.isLoaded && profileData.isLoaded,
  };
  return me;
};
