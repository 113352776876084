import { useMe } from "hooks/useMe";
import { usePrevious } from "hooks/usePrevious";
import moment from "moment";
import React from "react";
import { LeaderBoard } from "services/leaderboard";

export const LeaderboardContext = React.createContext(null);

export const LeaderboardProvider = ({ children }) => {
  const me = useMe();
  const previousMe = usePrevious(me);
  const [lbd, setLbd] = React.useState(null);
  const [contentLbd, setContentLbd] = React.useState(null);
  const [seriesLbd, setSeriesLbd] = React.useState(null);
  const [monthlyLbd, setMontlyLbd] = React.useState(null);
  const [weeklyLbd, setWeeklyLbd] = React.useState(null);
  const [contentId, setContentId] = React.useState(null);
  const [seriesId, setSeriesId] = React.useState(null);
  const [monthlyOffset, setMontlyOffset] = React.useState(0);
  const [weeklyOffset, setWeeklyOffset] = React.useState(0);

  React.useEffect(() => {
    if (previousMe && me && me.username) {
      //@ts-ignore
      if (me.username != previousMe.username) {
        if (lbd) {
          lbd.setUser(me.id, me.username);
          lbd.post(1, null, new Date());
        }
        if (monthlyLbd) {
          monthlyLbd.setUser(me.id, me.username);
          monthlyLbd.post(1, null, new Date());
        }
        if (weeklyLbd) {
          weeklyLbd.setUser(me.id, me.username);
          weeklyLbd.post(1, null, new Date());
        }
      }
    }
  }, [previousMe, me]);

  React.useEffect(() => {
    if (lbd && me && me.id) {
      if (lbd.userInfo && lbd.userInfo.userID == "") {
        lbd.setUser(me.id, me.username);
      }
    }
  }, [lbd, me]);

  React.useEffect(() => {
    if (monthlyLbd && me && me.id) {
      if (monthlyLbd.userInfo && monthlyLbd.userInfo.userID == "") {
        monthlyLbd.setUser(me.id, me.username);
      }
    }
  }, [monthlyLbd, me]);

  React.useEffect(() => {
    if (weeklyLbd && me && me.id) {
      if (weeklyLbd.userInfo && weeklyLbd.userInfo.userID == "") {
        weeklyLbd.setUser(me.id, me.username);
      }
    }
  }, [weeklyLbd, me]);

  React.useEffect(() => {
    if (contentLbd && me && me.id) {
      if (contentLbd.userInfo && contentLbd.userInfo.userID == "") {
        contentLbd.setUser(me.id, me.username);
      }
    }
  }, [contentLbd, me]);

  React.useEffect(() => {
    if (seriesLbd && me && me.id) {
      if (seriesLbd.userInfo && seriesLbd.userInfo.userID == "") {
        seriesLbd.setUser(me.id, me.username);
      }
    }
  }, [seriesLbd, me]);

  React.useEffect(() => {
    setupOverallLeaderboard();
  }, []);

  React.useEffect(() => {
    if (contentId) {
      if (!contentLbd || contentLbd.boardID != `content-${contentId}`) {
        setupContentLeaderboard(contentId);
      }
    }
  }, [contentId]);

  React.useEffect(() => {
    if (seriesId) {
      if (!seriesLbd || seriesLbd.boardID != `series-${contentId}`) {
        setupSeriesLeaderboard(seriesId);
      }
    }
  }, [seriesId]);

  const setupOverallLeaderboard = async () => {
    var board = new LeaderBoard({
      root: "Leaderboard",
      timeFilters: true,
      pageItemCount: 10,
      boardID: "overall",
    });
    setLbd(board);
  };
  const setupWeeklyLeaderboard = async () => {
    var wboard = new LeaderBoard({
      root: `Boards/weekly/leaderboard_${weekYearBase}`,
      timeFilters: true,
      pageItemCount: 10,
      boardID: "weekly_overall",
    });
    setWeeklyLbd(wboard);
  };
  const setupMonthlyLeaderboard = async () => {
    var mboard = new LeaderBoard({
      root: `Boards/monthly/leaderboard_${monthYearBase}`,
      timeFilters: true,
      pageItemCount: 10,
      boardID: "monthly_overall",
    });
    setMontlyLbd(mboard);
  };
  const setupContentLeaderboard = async (contentId: string) => {
    var cboard = new LeaderBoard({
      root: "Leaderboard",
      timeFilters: true,
      timeFilterType: "all",
      pageItemCount: 10,
      boardID: `content-${contentId}`,
    });
    setContentLbd(cboard);
  };
  const setupSeriesLeaderboard = async (seriesId: string) => {
    var sboard = new LeaderBoard({
      root: "Leaderboard",
      timeFilters: true,
      timeFilterType: "all",
      pageItemCount: 10,
      boardID: `series-${seriesId}`,
    });
    setSeriesLbd(sboard);
  };

  const monthYearBase = React.useMemo(() => {
    return moment()
      .subtract(monthlyOffset, "months")
      .startOf("month")
      .format("MM_YYYY");
  }, [monthlyOffset]);

  const weekYearBase = React.useMemo(() => {
    return moment()
      .subtract(weeklyOffset, "weeks")
      .startOf("week")
      .format("DD_MM_YYYY");
  }, [weeklyOffset]);

  React.useEffect(() => {
    setupMonthlyLeaderboard();
  }, [monthYearBase]);

  React.useEffect(() => {
    setupWeeklyLeaderboard();
  }, [weekYearBase]);

  const setLeaderboardContent = (contentId: string) => {
    setContentId(contentId);
  };

  const setLeaderboardSeries = (seriesId: string) => {
    setSeriesId(seriesId);
  };

  return (
    <LeaderboardContext.Provider
      value={{
        lbd,
        monthlyLbd,
        contentLbd,
        seriesLbd,
        weeklyLbd,
        weeklyOffset,
        setLeaderboardContent,
        setLeaderboardSeries,
        setMontlyOffset,
        setWeeklyOffset,
      }}
    >
      {children}
    </LeaderboardContext.Provider>
  );
};
