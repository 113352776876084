import React, { useState } from 'react';

export const SoftWallContext = React.createContext(null);

export const SoftWallProvider = ({ children }) => {
  const [showSoftWall, setShowSoftWall] = useState<boolean>(false);
  
  return (
    <SoftWallContext.Provider
      value={{
        showSoftWall,
        setShowSoftWall
      }}
    >
      {children}
    </SoftWallContext.Provider>
  );
};
