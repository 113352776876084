/*****************************************************************************
 * Import
 *****************************************************************************/
import React from "react";
import { AnimateKeyframes } from "react-simple-animate";
import { DefaultColors } from "models/colors";

/*****************************************************************************
 * Component
 *****************************************************************************/
export default function LoadingAnimation() {
  return (
    <div
      style={{
        zIndex: 10000,
        backgroundColor: DefaultColors.dark,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        flexDirection: "column",
        position: "fixed",
        top: 0,
        left: 0,
      }}
    >
      <AnimateKeyframes
        play
        iterationCount="infinite"
        duration={2}
        keyframes={[
          { 0: "transform: scale(0.9);" },
          { 50: "transform: scale(1.05);" },
          { 100: "transform: scale(0.95);" },
        ]}
      >
        <img
          alt="allude-icon"
          src={require("../../assets/icons/allude-icon.svg")}
          style={{ width: "20vw", height: "20vh" }}
        />
      </AnimateKeyframes>
    </div>
  );
}
