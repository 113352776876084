/*****************************************************************************
 * Import
 *****************************************************************************/
import firebase from "firebase";
import { Allusion } from "models/allusion";
import { Bookmark } from "models/bookmark";
import { Mention } from "models/mention";
import { User } from "models/user";

/*****************************************************************************
 * Public Functions
 *****************************************************************************/

export async function addNewContent(content) {
  const db = firebase.firestore();
  const newId = await fetchNewId();
  console.log(newId);
  const id = `ac${newId.toPrecision(8).split(".").reverse().join("")}`;
  const newContent = {
    ...content,
    id: id,
  };
  console.log(newContent);

  const contentRef = db.collection("Content").doc(id);
  contentRef.set(newContent);

  return newContent;
}

export async function addNewBookmark(bookmark: Bookmark) {
  const db = firebase.firestore();

  const contentRef = await db.collection("Bookmarks");
  const createResponse = await contentRef.add(bookmark);

  return { ...bookmark, id: createResponse.id };
}

export async function addNewMention(mention: Mention) {
  const db = firebase.firestore();

  const contentRef = await db.collection("Mentions");
  const createResponse = await contentRef.add(mention);

  return { ...mention, id: createResponse.id };
}

export async function addNewAllusion(allusion: Allusion) {
  const db = firebase.firestore();

  const contentRef = await db.collection("Allusions");
  const createResponse = await contentRef.add(allusion);

  return { ...allusion, id: createResponse.id };
}

export async function updateUser(user: User, updates: any) {
  const db = firebase.firestore();

  const contentRef = await db.collection("users").doc(user.id);
  await contentRef.update(updates);

  return { ...user, ...updates };
}

export async function incrementCounter(ref, field) {
  const db = firebase.firestore();
  
  try {
    await db.runTransaction(async transaction => {
      const doc = await transaction.get(ref);
      if(!doc.exists) {
        throw Error(`Doc to increment ${field} does not exist!`);
      }

      let data = doc.data();
      let count = data[field] || 0;
      count += 1;
      transaction.update(ref, { [field]: count });
    });
  } catch(error) {
    console.error('commitOrder::ERROR', error);
    throw error
  }
}

export async function decrementCounter(ref, field) {
  const db = firebase.firestore();
  
  try {
    await db.runTransaction(async transaction => {
      const doc = await transaction.get(ref);
      if(!doc.exists) {
        throw Error(`Doc to increment ${field} does not exist!`);
      }

      let data = doc.data();
      let count = data[field] || 0;
      count -= 1;
      if (count < 0) {
        count = 0;
        console.log("FIREBASE ATOMIC ERROR: decremented below 0!")
      }
      transaction.update(ref, { [field]: count });
    });
  } catch(error) {
    console.error('commitOrder::ERROR', error);
    throw error
  }
}

/*****************************************************************************
 * Private Functions
 *****************************************************************************/

async function fetchNewId() {
  const db = firebase.firestore();

  try {
    const countRef = db.collection("Content").doc("_counter");
    let newCount = null;
    await db.runTransaction(async (transaction) => {
      const countDoc = await transaction.get(countRef);
      if (!countDoc.exists) {
        throw Error("No _counter doc in Content!");
      }

      let { count } = countDoc.data();
      newCount = count + 1;
      transaction.update(countRef, { count: newCount });
    });

    return newCount;
  } catch (error) {
    console.error("commitOrder::ERROR", error);
    throw error;
  }
}
