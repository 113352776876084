import firebase from "firebase";
import { Allusion } from "models/allusion";
import { Content } from "models/content";
import { Tag } from "models/tag";
import { User } from "models/user";

export const getIdForTagString = (title: string) => {
  let stripped = title.replace(/[^a-zA-Z0-9\s]/g, "");
  let cleanedTagId = stripped.replace(/\s/g, "-");
  return cleanedTagId.toLowerCase();
};

export const getAllusionValueForTagTitle = (tagTitle) => {
  let stripped = tagTitle.replace(/[^a-zA-Z0-9\s]/g, "");
  return stripped.toLowerCase();
};

export const addTagToContent = async (tag: Tag, contentId: string) => {
  const db = firebase.firestore();
  let ref = db.collection("Content").doc(contentId);

  const cleanedTagTitle = tag.title.toLowerCase();
  await ref.update({
    tags: firebase.firestore.FieldValue.arrayUnion(cleanedTagTitle),
  });
};

export const addContentToTag = async (tag: Tag, contentId: string) => {
  const db = firebase.firestore();
  let ref = db.collection("Tags").doc(tag.id);

  await ref.update({
    contentIds: firebase.firestore.FieldValue.arrayUnion(contentId),
  });
};


export const addTagToSeries = async (tag: Tag, seriesid: string) => {
  const db = firebase.firestore();
  let ref = db.collection("Series").doc(seriesid);

  const cleanedTagTitle = tag.title.toLowerCase();
  await ref.update({
    tags: firebase.firestore.FieldValue.arrayUnion(cleanedTagTitle),
  });
};

export const getPostLinkForTag = (tag: Tag, basePath?: string) => {
  if (basePath && !basePath.includes("content")) {
    return `${basePath}?tags=${encodeURIComponent(tag.title).toLowerCase()}`;
  } else {
    return `/posts?tags=${encodeURIComponent(tag.title).toLowerCase()}`;
  }
};

export const getPostLinkForTagText = (tagString: string, basePath?: string) => {
  if (basePath && !basePath.includes("content")) {
    return `${basePath}?tags=${encodeURIComponent(tagString).toLowerCase()}`;
  } else {
    return `/posts?tags=${encodeURIComponent(tagString).toLowerCase()}`;
  }

  // `/posts?tags=${[...(highlightedTags || []), item]
  //   .join(",")
  //   .replaceAll(/\s/g, "+")}`
};

export const incrementTagUse = async (
  tag: Tag,
  contentId: string,
  seriesid: string
) => {
  const db = firebase.firestore();
  let ref = db.collection("Tags").doc(tag.id);

  await ref.update({
    useCount: firebase.firestore.FieldValue.increment(1),
  });

  if (contentId) {
    await addTagToContent(tag, contentId);
    await addContentToTag(tag, contentId);
  }
  if (seriesid) {
    await addTagToSeries(tag, seriesid);
  }
};

export const initNewTag = async (
  title: string,
  userId: string,
  contentId: string,
  seriesid: string
) => {
  const db = firebase.firestore();
  let tagId = getIdForTagString(title);
  let ref = db.collection("Tags").doc(tagId);

  let snapshot = await ref.get();

  if (snapshot.exists) {
    return incrementTagUse(
      { ...snapshot.data(), id: snapshot.id } as any,
      contentId,
      seriesid
    );
  } else {
    let newTag: Tag = {
      categories: [],
      title: title,
      id: tagId,
      official: false,
      useCount: 1,
      createdate: new Date(),
      createdby: userId,
      contentIds: [contentId],
    };
    await ref.set(newTag);
    if (contentId) {
      await addTagToContent(newTag, contentId);
      await addContentToTag(newTag, contentId);
    }
    if (seriesid) {
      await addTagToSeries(newTag, seriesid);
    }
  }
};

export const updateTagStatsForPost = (tags: Tag[], post: Allusion, content: Content, me: User) => {
  tags.forEach((tag) => {
    if (tag.id == null) {
      initNewTag(tag.title, me.id, content.id, content.seriesid);
    } else if (
      !(post?.tags || []).includes(tag.title.toLowerCase())
    ) {
      incrementTagUse(tag as any, content.id, content.seriesid);
    }
  });
}
