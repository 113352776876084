import React, { useState } from "react";

export const LoginContext = React.createContext(null);

export const LoginProvider = ({ children }) => {
  const [loginVisible, setLoginVisible] = useState<boolean>(false);
  const [loginAction, setLoginAction] = useState<
    "sign-in" | "sign-up" | "join-waitlist" | "enter-code" | "upgrade-anon"
  >("sign-in");
  const [loginNoClose, setLoginNoClose] = useState(false);
  const [loginRedirect, setLoginRedirect] = useState("");

  const login = (
    visible = true,
    action:
      | "sign-in"
      | "sign-up"
      | "join-waitlist"
      | "enter-code"
      | "upgrade-anon" = "sign-up",
    redirect = "",
    noClose = false,
  ) => {
    setLoginAction(action);
    setLoginVisible(visible);
    setLoginRedirect(redirect);
    setLoginNoClose(noClose);
  };

  return (
    <LoginContext.Provider
      value={{
        login,
        loginVisible,
        setLoginVisible,
        loginAction,
        setLoginAction,
        loginNoClose,
        setLoginNoClose,
        loginRedirect,
        setLoginRedirect,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};
