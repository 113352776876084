import { useContext, useState } from "react";
import { LoginContext } from "context/login-context";

export const useLogin = () => {
  const {
    login,
    loginVisible,
    setLoginVisible,
    loginAction,
    setLoginAction,
    loginRedirect,
    setLoginRedirect,
    loginNoClose,
    setLoginNoClose,
  } = useContext(LoginContext);
  
  return {
    login,
    loginVisible,
    setLoginVisible,
    loginAction,
    setLoginAction,
    loginRedirect,
    setLoginRedirect,
    loginNoClose,
    setLoginNoClose,
  };
}
