/*****************************************************************************
 * Import
 *****************************************************************************/
import { Box, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import discord from "assets/icons/discord.svg";
import instagram from "assets/icons/instagram.svg";
import linkedin from "assets/icons/linkedin.svg";
import twitter from "assets/icons/twitter.svg";
import GradientText from "components/text/gradient-text";
import Type from "components/text/type";
import { useMe } from "hooks/useMe";
import { useMobileCheck } from "hooks/useMobileCheck";
import React from "react";
import { Link } from "react-router-dom";
import LoggedInFooter from "./logged-in-footer";

/*****************************************************************************
 * Component
 *****************************************************************************/
interface FooterProps {
  absolute?: boolean;
}

export default function Footer({ absolute }: FooterProps) {
  const classes = useStyles();
  const isMobile = useMobileCheck();

  const me = useMe();
  const isLoggedIn = React.useMemo(() => {
    return me && me.isLoaded && !me.isEmpty && me.username;
  }, [me]);

  if (isLoggedIn) {
    return <LoggedInFooter absolute={absolute} />;
  }

  const footerInfoItems = [
    {
      name: "Terms",
      url: "/tos",
    },
    {
      name: "Privacy",
      url: "/privacy",
    },
    {
      name: "FAQ",
      url: "/help/#/faq",
    },
    {
      name: "Contact Us",
      url: "/contact",
    },
  ];

  const className = absolute
    ? `${classes.root} ${classes.absolute}`
    : classes.root;

  return isMobile ? (
    <Grid
      component="footer"
      container
      justifyContent="flex-start"
      className={className}
      style={{ padding: "56px 16px" }}
    >
      <Grid
        container
        direction="column"
        justifyContent={isMobile ? null : "space-evenly"}
        alignItems={isMobile ? "flex-start" : "center"}
      >
        <Grid
          item
          style={{
            padding: isMobile ? 0 : 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <GradientText
            style={{ fontWeight: 800, fontSize: 24 }}
            text={"Alluder"}
          />
          <a
            href="https://discord.gg/UzdMmJePff"
            style={{ textDecoration: "none", marginTop: 0 }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={discord}
                alt={"discord"}
                style={{
                  marginRight: 3,
                  width: 24,
                  height: 24,
                  verticalAlign: "bottom",
                }}
              />
              <Text>Discord</Text>
            </div>
          </a>
        </Grid>
        <Grid
          item
          style={{
            padding: 10,
            display: "flex",
            justifyContent: isMobile ? "flex-start" : "center",
            alignItems: isMobile ? "flex-start" : "center",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{
              fontWeight: 700,
              fontSize: 20,
              marginBottom: 4,
              padding: 0,
            }}
          >
            Resources
          </Typography>
          <FooterLinks items={[...footerInfoItems]} />
          <Type
            style={{
              marginTop: 56,
              fontWeight: 700,
              fontSize: 20,
              marginBottom: 8,
              padding: 0,
            }}
          >
            Support Us
          </Type>
          <SocialIcons />
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid
      component="footer"
      container
      justifyContent="center"
      className={className}
      style={{ paddingTop: 16, paddingBottom: 16 }}
    >
      <Grid container justifyContent="space-evenly" alignItems="center">
        <Grid
          item
          style={{
            padding: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <GradientText
            style={{ fontWeight: 800, fontSize: 24 }}
            text={"Alluder"}
          />
          <a
            href="https://discord.gg/UzdMmJePff"
            style={{ textDecoration: "none", marginTop: 0 }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={discord}
                alt={"discord"}
                style={{
                  marginRight: 3,
                  width: 24,
                  height: 24,
                  verticalAlign: "bottom",
                }}
              />
              <Text>Discord</Text>
            </div>
          </a>
        </Grid>
        <Grid
          item
          style={{
            padding: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{ fontWeight: 700, fontSize: 20, marginBottom: 4 }}
          >
            Resources
          </Typography>
          <FooterLinks items={[...footerInfoItems]} />
          <Type
            style={{
              marginTop: 30,
              fontWeight: 700,
              fontSize: 20,
              marginBottom: 8,
            }}
          >
            Support Us
          </Type>
          <SocialIcons />
        </Grid>
      </Grid>
    </Grid>
  );
}

/*****************************************************************************
 * Helper Components
 *****************************************************************************/

const FooterLinks = ({ items }) => {
  const isMobile = useMobileCheck();

  return isMobile ? (
    <Box display="flex" flexDirection="column" style={{}}>
      {items.map((item, idx) => (
        <React.Fragment key={idx}>
          <FooterLink to={item.url} disable={!item.url}>
            {item.name}
          </FooterLink>
        </React.Fragment>
      ))}
    </Box>
  ) : (
    <Grid container justifyContent="center" alignItems="center">
      {items.map((item, idx) => (
        <React.Fragment key={idx}>
          <FooterLink to={item.url}>{item.name}</FooterLink>
          {idx < items.length - 1 && <DividerDot />}
        </React.Fragment>
      ))}
    </Grid>
  );
};

/*****************************************************************************/

const SocialIcons = () => {
  const classes = useStyles();
  const isMobile = useMobileCheck();

  const baseIcons = [
    {
      src: instagram,
      url: `https://www.instagram.com/alluderapp/`,
      alt: "instagram",
      title: "instagram",
    },
    {
      src: twitter,
      title: "Twitter",
      url: `https://twitter.com/alluderapp`,
      alt: "twitter",
    },

    {
      src: linkedin,
      title: "Linkedin",
      url: `https://www.linkedin.com/company/alluder`,
      alt: "linkedin",
    },
  ];

  return (
    <Box
      display="flex"
      style={{
        gap: isMobile ? 2 : 20,
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      {baseIcons.map((icon, idx) => (
        <a
          key={idx}
          href={icon.url}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <img
            src={icon.src}
            alt={icon.alt}
            style={{
              marginLeft: isMobile ? 0 : 2,
              width: 24,
              height: 24,
              verticalAlign: "bottom",
            }}
          />
          <Text>{icon.title}</Text>

          {isMobile ? (
            <div />
          ) : idx < baseIcons.length - 1 ? (
            <DividerDot />
          ) : (
            <div />
          )}
        </a>
      ))}
    </Box>
  );
};

/*****************************************************************************/

const Text = ({ children }) => {
  const isMobile = useMobileCheck();

  return (
    <Typography
      style={
        isMobile
          ? {
              color: "#FFF",
              fontWeight: 400,
              textAlign: "left",
              fontSize: 14,
            }
          : {
              color: "#FFF",
              fontWeight: 400,
              textAlign: "center",
              fontSize: 16,
            }
      }
    >
      {children}
    </Typography>
  );
};

/*****************************************************************************/

const FooterLink = (props: { children; to; disable? }) => {
  const classes = useStyles();

  return props.disable || !props.to ? (
    <span style={{ opacity: 0.33 }}>
      <Text>{props.children}</Text>
    </span>
  ) : (
    <Link
      to={props.to}
      style={{ textDecoration: "none" }}
      className={classes.link}
    >
      <Text>{props.children}</Text>
    </Link>
  );
};

/*****************************************************************************/

const DividerDot = () => (
  <div
    style={{
      width: 4,
      height: 4,
      borderRadius: 2,
      marginLeft: 8,
      marginRight: 8,
      backgroundColor: "#fff",
      opacity: 0.5,
    }}
  />
);

/*****************************************************************************/

const Divider = () => (
  <div
    style={{
      minHeight: 1,
      minWidth: "100%",
      background: "rgba(255,255,255,0.1)",
    }}
  />
);

/*****************************************************************************
 * Style
 *****************************************************************************/
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiGrid-spacing-xs-2": {
        width: `calc(100% + 16px) !important`,
        margin: "-8px !important",
      },
      [theme.breakpoints.down(760)]: {
        paddingRight: 0,
        background: "#1F1F1F",
      },
      [theme.breakpoints.up(760)]: {
        background:
          "linear-gradient(134.63deg, #1F1F1F 36.79%, #161616 141.57%)",
      },
    },
    absolute: {
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
    },
    link: {
      cursor: "pointer",
      opacity: 0.66,
      transition: "opacity 0.5",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      textDecoration: "none",
      "&:hover": {
        opacity: 1,
      },
    },
  })
);
