import { ThemeProvider } from "@material-ui/styles";
import firebase from "firebase";
import { createHashHistory } from "history";
import { DefaultColors } from "models/colors";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { AnimateKeyframes } from "react-simple-animate";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createFirestoreInstance } from "redux-firestore";
import theme from "setup/theme";
import AlludeRouter from "./navigation/router/router";
import createStore from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { AlertProvider } from "context/alert-context";
import { LoginProvider } from "context/login-context";
import { StreamingSelectProvider } from "context/streaming-select-context";
import { SoftWallProvider } from "context/soft-wall-context";
import env from "react-dotenv";
import NetflixHuntRouter from "netflixhunt/navigation/nh-router";
import { LeaderboardProvider } from "context/leaderboard-context";
import { useAlert } from "hooks/useAlert";
import Alert from "modals/alert";

const history = createHashHistory();

const initialState = {};

const { store, persistor } = createStore(initialState, history);

const rfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
  useFirestoreForStorageMeta: true,
};

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReactReduxFirebaseProvider
          firebase={firebase}
          config={rfConfig}
          dispatch={store.dispatch}
          createFirestoreInstance={createFirestoreInstance}
        >
          <ThemeProvider theme={theme}>
            <LoginProvider>
              <LeaderboardProvider>
                <AlertProvider>
                  <SoftWallProvider>
                    <StreamingSelectProvider>
                      <AlludeApp />
                    </StreamingSelectProvider>
                  </SoftWallProvider>
                </AlertProvider>
              </LeaderboardProvider>
            </LoginProvider>
          </ThemeProvider>
        </ReactReduxFirebaseProvider>
      </PersistGate>
    </Provider>
  );
}

const AlludeApp = () => {
  const { showAlert, setShowAlert, alertVariant, alertIcon, alertTitle } =
    useAlert();
  
  return (
    <React.Fragment>
      <AlludeRouter />
      <ToastContainer draggable={false} />
      <Alert
        variant={alertVariant}
        icon={alertIcon}
        title={alertTitle}
        visible={showAlert}
        setVisible={setShowAlert}
        timeout={5000}
      />
    </React.Fragment>
  );
}
