import { createTheme } from "@material-ui/core";
import { DefaultColors } from "models/colors";
//@ts-ignore
import poppins from "typeface-poppins";

const white = "#ECECEC";
const black = "#32CD32";

const theme = createTheme({
  palette: {
    type: "light",

    primary: {
      light: "#fedb31",
      main: "#fedb31",
      dark: "#fedb31",
      contrastText: white,
    },
    secondary: {
      light: "rgb(143, 143, 143)",
      main: "rgb(143, 143, 143)",
      dark: "rgb(143, 143, 143)",
      contrastText: black,
    },

    background: {
      default: "#14171B",
    },

    divider: "#DFE3E8",
  },
  typography: {
    fontFamily: "Poppins",

    subtitle1: {
      fontFamily: "Poppins",
      fontSize: "2rem",
      fontWeight: "bold",
    },
    subtitle2: {
      fontFamily: "Poppins",
      fontSize: "1.4rem",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 800,
    },
    h4: {
      fontSize: "1.2rem",
      fontWeight: 700,
    },
    body1: {
      fontFamily: "Poppins",
      fontWeight: 300,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [poppins],
        // tara later
        // "*::-webkit-scrollbar": {
        //   display: "none",
        // },
        // "*::-webkit-scrollbar-track": {
        //   display: "none",
        // },
        // "*::-webkit-scrollbar-thumb": {
        //   display: "none",
        // },
      },
    },

    MuiGrid: {
      container: {
        width: "100% !important",
        margin: "0 !important",
      },
    },

    MuiSelect: {
      icon: {
        color: "inherit",
      },
    },

    MuiButton: {
      containedPrimary: {
        background: DefaultColors.gradientColor,
        color: "white",
        borderRadius: "17px",
        textTransform: "none",
        fontSize: "0.9rem",
      },
      containedSecondary: {
        color: "var(--highlight)",
        backgroundColor: "var(--background-button)",
        borderRadius: "17px",
        textTransform: "none",
        fontSize: "0.9rem",
      },
    },

    MuiInputBase: {
      root: {
        fontFamily: "Poppins",
        overflow: "hidden",
        borderRadius: 8,
      },
    },

    MuiTypography: {
      root: {
        color: white,
      },
      h4: {
        textDecoration: "none",
      },
    },

    MuiFormLabel: {
      root: {
        color: "#878787",
      },
    },

    MuiOutlinedInput: {
      root: {
        color: "white",
        overflow: "visible",

        "& fieldset": {
          border: "2px solid rgba(255, 255, 255, 0.25)",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: "2px solid rgba(255, 255, 255, 0.25)",
        },
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            border: "solid 2px transparent",
            backgroundClip: "padding-box",
            backgroundColor: "#14171B",
            boxSizing: "border-box",
            zIndex: "-1",
            borderRadius: "8px",
            alignItems: "center",
            marginTop: "0px",
          },
          overflow: "visible",
          background: DefaultColors.gradientColorReverse,
          zIndex: "1",
          border: "none",
          borderRadius: "8px",
        },
      },
      input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 300px #14171B inset",
          WebkitTextFillColor: "white",
        },

        background: "none",
      },
    },
    MuiInputLabel: {
      outlined: {
        "&.MuiInputLabel-shrink": {
          zIndex: "4",
          backgroundColor: "rgb(20, 23, 27)",
          paddingBottom: "2px",
          paddingLeft: "8px",
          paddingRight: "8px",
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontFamily: "Poppins",
        fontSize: "0.8rem",
      },
    },
  },
});

export default theme;
