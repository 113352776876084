import { useContext, useState } from "react";
import { AlertContext } from "context/alert-context";

export const useAlert = () => {
  const {
    showAlert,
    setShowAlert,
    alertVariant,
    setAlertVariant,
    alertIcon,
    setAlertIcon,
    alertTitle,
    setAlertTitle,
  } = useContext(AlertContext);

  function alert(title, icon, variant) {
    setAlertTitle(title);
    setAlertIcon(icon);
    setAlertVariant(variant);
    setShowAlert(true);
  }
  
  return {
    alert,
    showAlert,
    setShowAlert,
    alertVariant,
    setAlertVariant,
    alertIcon,
    setAlertIcon,
    alertTitle,
    setAlertTitle,
  };
}
