import React from "react";

export class AnalyticsService {
  static logEvent = (eventName: string, params: any) => {
    //@ts-ignore
    if (window.gtag) {
      //@ts-ignore
      window.gtag("event", eventName, params);
    }
  };

  static setUserId = (userId: string) => {
    //@ts-ignore
    window.gtag("config", "UA-172803884-1", {
      user_id: userId,
    });
    //@ts-ignore
    window.gtag("config", "G-DQ9V9DSDVE", {
      user_id: userId,
    });
  };

  static setClientId = (clientId: string) => {
    //@ts-ignore
    window.gtag("set", {
      clientId: clientId,
      client_id: clientId,
      cid: clientId,
      dimension21: clientId,
    });
    //@ts-ignore
    window.dataLayer.push({
      event: "clientId",
      clientId: clientId,
    });
  };

  static logScreen = (path: string) => {
    let name = path.substring(1);
    if (path.includes("ifr/allusion/")) {
      name = "iframe-allusion-display";
    } else if (path.includes("ifr/create/")) {
      name = "iframe-allusion-create";
    } else if (path.includes("ifr/icon/")) {
      name = "iframe-icon";
    } else if (path.includes("series/")) {
      name = "series-page";
    } else if (path.includes("series")) {
      name = "all-series";
    } else if (path.includes("profile")) {
      name = "profile";
    } else if (path.includes("bookmarks")) {
      name = "bookmarks";
    } else if (path.includes("content/")) {
      name = "timeline-page";
    }
    //@ts-ignore
    window.gtag("config", "UA-172803884-1", {
      page_title: name,
      page_path: path,
    });
    //@ts-ignore
    window.gtag("config", "G-DQ9V9DSDVE", {
      page_title: name,
      page_path: path,
    });
  };
}
