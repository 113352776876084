import React, { useState } from 'react';

export const AlertContext = React.createContext(null);

export const AlertProvider = ({ children }) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertVariant, setAlertVariant] = useState<
  "success" | "warn" | "error"
  >("success");
  const [alertIcon, setAlertIcon] = useState<React.ReactElement>(<div />);
  const [alertTitle, setAlertTitle] = useState("");
  
  return (
    <AlertContext.Provider
      value={{
        showAlert,
        setShowAlert,
        alertVariant,
        setAlertVariant,
        alertIcon,
        setAlertIcon,
        alertTitle,
        setAlertTitle,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};
