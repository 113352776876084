/*****************************************************************************
 * Import
 *****************************************************************************/
import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import { Box, Typography, Grid } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import { CTAButton } from "components/buttons/cta-button";
import { useMe } from "hooks/useMe";
import { useLogin } from "hooks/useLogin";
import { useMobileCheck } from "hooks/useMobileCheck";

import facebook from "assets/icons/facebook.svg";
import instagram from "assets/icons/instagram.svg";
import linkedin from "assets/icons/linkedin.svg";
import twitter from "assets/icons/twitter.svg";

/*****************************************************************************
 * Component
 *****************************************************************************/
interface FooterProps {
  absolute?: boolean;
}

export default function LoggedInFooter({ absolute }: FooterProps) {
  const classes = useStyles();
  const isMobile = useMobileCheck();

  const me = useMe();
  const isLoggedIn = React.useMemo(() => {
    return me && me.isLoaded && !me.isEmpty && me.username;
  }, [me]);

  const footerInfoItems = [
    {
      name: "GUIDE",
      url: "/help/#/guide",
    },
    {
      name: "TUTORIALS",
      url: "/help/#/tutorials",
    },
    {
      name: "FAQ",
      url: "/help/#/faq",
    },
    {
      name: "CONTACT US",
      url: "/contact",
    },
    {
      name: "ABOUT US",
      url: "/alluder-101/about-us",
    },
  ];

  const footerLegalItems = [
    {
      name: "TERMS",
      url: "/tos",
    },
    {
      name: "PRIVACY",
      url: "/privacy",
    },
    {
      name: "CONTENT",
      url: "/content-policy",
    },
    {
      name: "COOKIES",
      url: "/cookie-notice",
    },
    {
      name: "COPYRIGHT © 2022",
      url: null,
    },
  ];

  const className = absolute
    ? `${classes.root} ${classes.absolute}`
    : classes.root;

  return isMobile ? (
    <Box
      component="footer"
      py="16px"
      px="16px"
      display="flex"
      flexDirection="column"
      style={{ gap: "16px" }}
      className={`${className} mobile`}
    >
      <SocialIcons />
      <Divider />
      <FooterLinks items={footerInfoItems} />
      <Divider />
      <FooterLinks items={footerLegalItems} />
    </Box>
  ) : (
    <Grid
      component="footer"
      container
      justifyContent="center"
      className={className}
    >
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Grid item style={{ padding: 20 }}>
          <FooterLinks items={[...footerInfoItems, ...footerLegalItems]} />
        </Grid>
        <Grid item style={{ padding: isLoggedIn ? 20 : 10 }}>
          {isLoggedIn ? <SocialIcons /> : <GetStartedButton />}
        </Grid>
      </Grid>
    </Grid>
  );
}

/*****************************************************************************
 * Helper Components
 *****************************************************************************/

const FooterLinks = ({ items }) => {
  const isMobile = useMobileCheck();

  return isMobile ? (
    <Box display="flex" flexDirection="column" style={{ gap: "16px" }}>
      {items.map((item, idx) => (
        <React.Fragment key={idx}>
          <FooterLink to={item.url} disable={!item.url}>
            {item.name}
          </FooterLink>
        </React.Fragment>
      ))}
    </Box>
  ) : (
    <Grid container justifyContent="center" alignItems="center">
      {items.map((item, idx) => (
        <React.Fragment key={idx}>
          <FooterLink to={item.url}>{item.name}</FooterLink>
          {idx < items.length - 1 && <DividerDot />}
        </React.Fragment>
      ))}
    </Grid>
  );
};

/*****************************************************************************/

const GetStartedButton = () => {
  const { login } = useLogin();

  return (
    <CTAButton
      onClick={() => {
        login(true, "sign-up");
        window.open(
          "https://chrome.google.com/webstore/detail/alluder/bipedkmibhgkhbomdbmfockefdphehkd?hl=en",
          "_blank"
        );
      }}
      icon={<CloudDownloadIcon />}
      text={"GET STARTED - IT'S FREE!"}
    />
  );
};

/*****************************************************************************/

const SocialIcons = () => {
  const classes = useStyles();
  const isMobile = useMobileCheck();

  const icons = [
    {
      src: twitter,
      url: `https://twitter.com/alluderapp`,
      alt: "twitter",
    },
    {
      src: instagram,
      url: `https://www.instagram.com/alluderapp/`,
      alt: "instagram",
    },
    {
      src: linkedin,
      url: `https://www.linkedin.com/company/alluder`,
      alt: "linkedin",
    },
  ];

  return (
    <Box display="flex" style={{ gap: isMobile ? 24 : 20 }}>
      {icons.map((icon, idx) => (
        <a
          key={idx}
          href={icon.url}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <img
            src={icon.src}
            alt={icon.alt}
            style={{
              width: 24,
              height: 24,
              verticalAlign: "bottom",
            }}
          />
        </a>
      ))}
    </Box>
  );
};

/*****************************************************************************/

const Text = ({ children }) => {
  const isMobile = useMobileCheck();

  return (
    <Typography
      style={
        isMobile
          ? {
              color: "#FFF",
              fontWeight: 700,
              fontSize: "10px",
              lineHeight: "20px",
              letterSpacing: "0.1em",
              textTransform: "uppercase",
            }
          : {
              color: "#FFF",
              fontWeight: 800,
              textAlign: "center",
              fontSize: 12,
            }
      }
    >
      {children}
    </Typography>
  );
};

/*****************************************************************************/

const FooterLink = (props: { children; to; disable? }) => {
  const classes = useStyles();

  return props.disable || !props.to ? (
    <span style={{ opacity: 0.33 }}>
      <Text>{props.children}</Text>
    </span>
  ) : (
    <Link
      to={props.to}
      style={{ textDecoration: "none" }}
      className={classes.link}
    >
      <Text>{props.children}</Text>
    </Link>
  );
};

/*****************************************************************************/

const DividerDot = () => (
  <div
    style={{
      width: 4,
      height: 4,
      borderRadius: 2,
      marginLeft: 8,
      marginRight: 8,
      backgroundColor: "#fff",
      opacity: 0.5,
    }}
  />
);

/*****************************************************************************/

const Divider = () => (
  <div
    style={{
      minHeight: 1,
      minWidth: "100%",
      background: "rgba(255,255,255,0.1)",
    }}
  />
);

/*****************************************************************************
 * Style
 *****************************************************************************/
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiGrid-spacing-xs-2": {
        width: `calc(100% + 16px) !important`,
        margin: "-8px !important",
      },
      [theme.breakpoints.down(760)]: {
        minHeight: "60px",
        paddingRight: 0,
        background: "#1F1F1F",
      },
      [theme.breakpoints.up(760)]: {
        minHeight: "60px",
        background:
          "linear-gradient(134.63deg, #1F1F1F 36.79%, #161616 141.57%)",
      },
    },
    absolute: {
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
    },
    link: {
      cursor: "pointer",
      opacity: 0.66,
      transition: "opacity 0.5",

      "&:hover": {
        opacity: 1,
      },
    },
  })
);
