export const firebaseConfig = {
  apiKey: "AIzaSyCQ_swwzyzGwW84-GpOMjkEcgQ9EGTS2ZI",
  authDomain: "allude-99708.firebaseapp.com",
  databaseURL: "https://allude-99708.firebaseio.com",
  projectId: "allude-99708",
  storageBucket: "allude-99708.appspot.com",
  messagingSenderId: "730840922357",
  appId: "1:730840922357:web:858d4b5f3a5dc71ff4edbc",
  measurementId: "G-7ZXQF95JX1",
};

export const devFirebaseConfig = {
  apiKey: "AIzaSyD2SvXR7zdLsu9MH4d2QpDIhuoCTvDKLcE",
  authDomain: "allude-dev-d7cc8.firebaseapp.com",
  projectId: "allude-dev-d7cc8",
  storageBucket: "allude-dev-d7cc8.appspot.com",
  messagingSenderId: "925928104981",
  appId: "1:925928104981:web:8982e1436ceee7aa595a1c",
  measurementId: "G-M8KDTY213Q"
};
