export const DefaultColors = {
  dark: "#0C1013", //darkblack
  light: "#2a2a2a", //lightblack
  contrast: "#FEDB31", //yellow
  contrastDarker: "#F5C343",
  contrastDark: "#FD8824", // orange
  gradientColor: "linear-gradient(to left, #FEDB31, #FD8824)",
  gradientColorReverse: "linear-gradient(to left, #FD8824, #FEDB31)",
  gradientVerticalColor: "linear-gradient(to bottom, #FEDB31, #FD8824)",
  gradientVerticalColorReverse: "linear-gradient(to top, #FD8824, #FEDB31)",
  red: "#f44336",
  netflixRed: "#DB202C",
};
