/*****************************************************************************
 * Imports
 *****************************************************************************/
import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Type from "components/text/type";

import { CircleX } from "tabler-icons-react";

/*****************************************************************************
 * Component
 *****************************************************************************/

interface AlertProps {
  variant: "success" | "warn" | "error",
  icon: any,
  title: any,
  visible: boolean,
  setVisible: Function,
  timeout?: number,
}

function Alert({ variant, icon, title, timeout, visible, setVisible }: AlertProps) {
  const classes = useStyles();

  useEffect(() => {
    if (visible && timeout) {
      setTimeout(() => {
        setVisible(false);
      }, timeout)
    }
  }, [visible])

  const stripColor = {
    success: "#1DA462",
    warn: "linear-gradient(90.35deg, #FEDB31 1.97%, #FD8824 98%)",
    error: "#DB202C",
  }[variant];
  
  return (
    <Box
      pr="32px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      style={{gap: "16px", display: visible ? null : "none"}}
      className={classes.root}
    >
      <Box display="flex" alignItems="center" style={{gap: "16px", height: "100%"}}>
        <Box className={classes.colorStripe} style={{background: stripColor}} />
        {icon}
        <Type variant="b2">{title}</Type>
      </Box>
      <CircleX onClick={() => setVisible(false)} size="22px" style={{cursor: "pointer"}} />
    </Box>
  );
}

/*****************************************************************************
 * Style
 *****************************************************************************/
const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "16px",
    left: "50%",
    transform: "translateX(-50%)",
    minWidth: "33vw",
    //minWidth: "465px",
    height: "54px",
    background: "#333333",
    borderRadius: "5.4px",
    zIndex: 2000,
  },
  colorStripe: {
    width: "16px",
    height: "100%",
    borderRadius: "5.4px 0 0 5.4px",
  }
}));

/*****************************************************************************
 * Export
 *****************************************************************************/
export default Alert;
